define("ember-truth-helpers/helpers/gte", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gte = gte;
  _exports.default = void 0;

  function gte(_ref, hash) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        left = _ref2[0],
        right = _ref2[1];

    if (hash.forceNumber) {
      if (typeof left !== 'number') {
        left = Number(left);
      }

      if (typeof right !== 'number') {
        right = Number(right);
      }
    }

    return left >= right;
  }

  var _default = Ember.Helper.helper(gte);

  _exports.default = _default;
});